import { useEffect } from 'react'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBCardImage, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { getAnnouncement, setLoginModal } from '../../../redux/reducer/orderReducer'

//component
import Announcement from '../../../components/common/announcement'

//service
import authServices from '../../../services/auth.services'

export default function Account() {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const { announcement, announcement_loading } = useSelector((state) => state.order)

  const username = localStorage.getItem('username')

  const openWhatsApp = () => {
    window.open('https://wa.me/0123456789', '_blank')
  }

  const handleLogout = () => {
    authServices.logout()
    window.location.reload()
  }

  useEffect(() => {
    dispatch(
      getAnnouncement({
        search: '',
        start: '',
        length: '',
      }),
    )
  }, [dispatch])

  return (
    <MDBContainer className="order-account-container">
      <MDBRow className="user-detail-container">
        <MDBCol className="profile-pic">
          <MDBCardImage
            // src={require('../../../assets/images/profile_pic.webp')}
            src={
              localStorage.getItem('token')
                ? require('../../../assets/images/profile_pic.webp')
                : require('../../../assets/images/profile_pic_after_login.webp')
            }
            width={width >= 991 ? 90 : 70}
            alt="ready-stock-car-img"
          />
        </MDBCol>
        <MDBCol>
          <div className="button-action">
            {!localStorage.getItem('token') && (
              <label className="_label --username" onClick={() => dispatch(setLoginModal(true))}>
                Login
              </label>
            )}
            {/* {!localStorage.getItem('token') && (
              <label className="_label --action" onClick={() => dispatch(setLoginModal(true))}>
                Sign up
              </label>
            )} */}
            {localStorage.getItem('token') && (
              <label className="_label --username">{username}</label>
            )}
            {localStorage.getItem('token') && (
              <label className="_label --action" onClick={handleLogout}>
                Sign Out
              </label>
            )}
          </div>
          {localStorage.getItem('token') ? (
            <label className="_label --desc-detail">
              You Can Check Your Order Status & Car Document.
            </label>
          ) : (
            <label className="_label --desc-detail">Click to log in, dream car never lost</label>
          )}
        </MDBCol>
      </MDBRow>
      {!announcement_loading && (
        <div className="_boder">
          <Announcement data={announcement} />
        </div>
      )}

      {/* <MDBRow className="main-title-row">
        <label className="_title-special-text">
          D - <span className="_title">My Account</span>
        </label>
      </MDBRow> */}
      {/* <div className="order-account-content">
        <MDBRow className="text-center justify-content-center gap-3">
          {
            //if token is same then show order history
            localStorage.getItem('token') ? (
              <MDBContainer className="after-login-container">
                <MDBRow className="text-start">
                  <p className="_text">
                    Hi, Welcome <b>+6{username}</b>.
                  </p>
                </MDBRow>
                <MDBRow>
                  <MDBCol className={`d-flex justify-content-${width < 500 ? 'center' : 'end'}`}>
                    <div className="_button">
                      <MDBBtn className="button-group --primary-button" onClick={openWhatsApp}>
                        Live Support
                      </MDBBtn>
                    </div>
                  </MDBCol>
                  <MDBCol className={`d-flex justify-content-${width < 500 ? 'center' : 'start'}`}>
                    <div className="_button">
                      <MDBBtn className="button-group --primary-button" onClick={handleLogout}>
                        Logout
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            ) : (
              <>
                <MDBRow
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    marginTop: '-3em',
                    marginBottom: '3em',
                    marginLeft: '0',
                    marginRight: '0',
                    color: '#D1D5DB',
                    fontFamily: 'cabin-regular',
                  }}>
                  <Lottie animationData={YellowLoadingCar} style={animationStyle} />
                </MDBRow>
                <div>
                  <MDBBtn
                    className="button-group --primary-button"
                    onClick={() => dispatch(setLoginModal(true))}>
                    Login / Sign Up
                  </MDBBtn>
                </div>
              </>
            )
          }
        </MDBRow>
      </div> */}
    </MDBContainer>
  )
}
