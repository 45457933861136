//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'

//redux
import { useDispatch, useSelector } from 'react-redux'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//component
import OrderCard from './order.card'
import { useEffect } from 'react'
import { getOrderHistory } from '../../../redux/reducer/orderReducer'
import { Skeleton } from 'primereact/skeleton'

export default function OrderHistory() {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const token = localStorage.getItem('token')
  const { orderDetails, history_loading } = useSelector((state) => state.order)

  const completedTask = orderDetails?.map((order) => {
    const { task } = order
    const completedTasks = task?.filter((item) => item.status === 'Completed')
    return completedTasks ? completedTasks : []
  })

  useEffect(() => {
    if (token) {
      dispatch(getOrderHistory())
    }
  }, [dispatch, token])

  if (orderDetails && orderDetails.length > 0) {
    return (
      <MDBContainer className="my-order-history-container">
        {/* {isOpenWebShareModal && <WebShareModal />} */}
        <MDBRow className="main-title-row">
          <label className="_title-special-text">
            D - <span className="_title">ORDER HISTORY</span>
          </label>
        </MDBRow>
        {!history_loading ? (
          <div className="order-history-content">
            {orderDetails.map((item, index) => (
              <OrderCard
                data={{ ...item, task: completedTask[index] }}
                loading={history_loading}
                complete={completedTask}
              />
            ))}
          </div>
        ) : (
          <>
            {width >= 500 ? (
              <>
                <Skeleton width="100%" height="7em" className="mt-4 mb-2 ms-4" />
                <Skeleton width="100%" height="7em" className="mt-4 mb-2 ms-4" />
              </>
            ) : (
              <>
                <Skeleton width="100%" height="7em" className="mt-4 mb-2 ms-4" />
                <Skeleton width="100%" height="7em" className="mt-4 mb-2 ms-4" />
              </>
            )}
          </>
        )}
      </MDBContainer>
    )
  }
}
