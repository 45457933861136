//lib
import { Icon } from '@iconify/react'

//reducer
import { useDispatch } from 'react-redux'
import { closeSignModal } from '../../redux/reducer/orderReducer'

//hook
import useWindowDimensions from '../common/window-dimension'

//component
import ModalBox from './full.modal.box'

import FileViewer from 'react-file-viewer'

//assets
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function EventModal({ show, setShow, file }) {
  const { height, width } = useWindowDimensions()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onDialogClose = () => {
    dispatch(closeSignModal())
  }

  return (
    <ModalBox
      className={'event-modal-container'}
      show={show}
      setShow={setShow}
      staticBackdrop={true}
      desktopModal={false}
      // desktopModal={width >= 991 ? true : false}
      // screenSize="lg"
      onDismiss={onDialogClose}
      backButton={onDialogClose}
      content={
        <div>
          <div style={{ textAlign: 'end' }}>
            <Icon
              onClick={onDialogClose}
              className="icon-close-btn"
              icon="fontisto:close"
              width={30}
              height={30}
            />
          </div>
          {/* <FileViewer key={1} fileType={'pdf'} filePath={file} /> */}
        </div>
      }
    />
  )
}
